import Muuri from 'muuri';
import type Grid from "muuri";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class NewslettersFilters {
    private muuri: Grid | null = null;
    private monthFilter = '';

    constructor() {

        const grid = document.querySelector('.newsletter-wrapper');

        if (!grid) {
            return;
        }

        this.muuri = new Muuri('.newsletter-wrapper');

        const monthSelector = document.querySelector('.month-selector');
        const monthFilters = document.querySelectorAll('.month__item') as NodeListOf<HTMLElement>;

        if (!monthSelector) {
            console.warn('Missing Filter Selector');
            return;
        }

        const monthDropdown = tippy(monthSelector, {
            content: document.querySelector('.month-dropdown') as any,
            allowHTML: true,
            interactive: true,
            trigger: 'click',
            placement: 'bottom',
            arrow: false,
            theme: 'newsletter-dropdown',
            onShow() {
                monthSelector.classList.add('is-active');
            },
            onHide() {
                monthSelector.classList.remove('is-active');
            }
        });

        monthFilters.forEach((el) => {
            el.addEventListener('click', () => {
                const value = el.dataset.value;
                if (!value) {
                    return;
                }

                if (this.monthFilter === value) {
                    monthSelector.querySelector('span')!.textContent = 'Please Select';
                    this.monthFilter = '';
                } else {
                    monthSelector.querySelector('span')!.textContent = el.textContent;
                    this.monthFilter = value;
                }

                monthDropdown.hide();

                this.updateFilter();
            })
        });
    }    

    updateFilter() {
        this.muuri?.filter((item: any) => {
            if (!this.monthFilter.length) {
                return true;
            }

            const values = JSON.parse(item.getElement().dataset.categories);

            const rolePass = !this.monthFilter.length || values.includes(this.monthFilter);

            return rolePass;
        });
    }

}            