export default class LocationMap {
    constructor() {
        const svgElements = document.querySelectorAll('.location-map-pin');
        const detailElements = document.querySelectorAll('.location-map-details');
        
        svgElements.forEach((item) => {
            item.addEventListener('click', function() {
                detailElements.forEach(element => {
                    element.classList.remove('is-active')
                });

                const svgLocation = item.id;
                console.log(svgLocation)

                detailElements.forEach((element) => {
                    if(element.classList.contains(`location-details-${svgLocation}`)) {
                        element.classList.add('is-active')
                    }
                })
                // console.log(detailElement)
            })
        })
    };
}
