import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

import 'swiper/css';

export default class PetTypeSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.pets-swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const PetTypeSwiper = new Swiper('.pets-swiper', {

                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                breakpoints: {
                    700: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        width: null,
                    }
                },

                slidesPerView: 1,
                spaceBetween: 20,
                width: 300,


                modules: [ Navigation ],

            })
        })
    }
}
