import tippy, {followCursor} from 'tippy.js';
import 'tippy.js/dist/tippy.css';


export default class PetHealthChecker {
    constructor() {
        const catLink = document.getElementById('cats');
        const dogLink = document.getElementById('dogs');
        const rabbitLink = document.getElementById('rabbits')
        const catContent = document.getElementById('cats-content');
        const dogContent = document.getElementById('dogs-content');
        const rabbitContent = document.getElementById('rabbits-content');
        const optionLinks = document.getElementById('pet-health-check-options-wrapper');
        const dogBackButton = document.getElementById('health-check-back-dog');
        const catBackButton = document.getElementById('health-check-back-cat');
        const rabbitBackButton = document.getElementById('health-check-back-rabbit')

        catLink?.addEventListener('click', function() {
            showContent(catContent);
        });
        
        dogLink?.addEventListener('click', function() {
            showContent(dogContent);
        });

        rabbitLink?.addEventListener('click', function() {
            showContent(rabbitContent);
        });

        dogBackButton?.addEventListener('click', function() {
            showOptions();
        })

        catBackButton?.addEventListener('click', function() {
            showOptions();
        })

        rabbitBackButton?.addEventListener('click', function() {
            showOptions();
        })

        function showOptions() {
            document.querySelectorAll('.pet-health-check-content-wrapper div').forEach(function(div) {
                div.classList.add('is-hidden');
                optionLinks?.classList.remove('is-hidden');
            });
        }
        
        function showContent(content :any) {
            document.querySelectorAll('.pet-health-check-content-wrapper div').forEach(function(div) {
                div.classList.add('is-hidden');
                optionLinks?.classList.add('is-hidden');
            });

            content.classList.remove('is-hidden');
        }

        const tooltipsDogs = [
            { trigger: '.healthcheck__teeth-icon-dog', content: 'dogs_teeth-tooltip' },
            { trigger: '.healthcheck__eyes-icon-dog', content: 'dogs_eyes-tooltip' },
            { trigger: '.healthcheck__ears-icon-dog', content: 'dogs_ears-tooltip' },
            { trigger: '.healthcheck__feet-and-claws-icon-dog', content: 'dogs_feet_and_claws-tooltip' },
            { trigger: '.healthcheck__skin-and-coat-icon-dog', content: 'dogs_skin_and_coat-tooltip' },
            { trigger: '.healthcheck__mobility-icon-dog', content: 'dogs_mobility-tooltip' },
            { trigger: '.healthcheck__abdomen-icon-dog', content: 'dogs_abdomen-tooltip' },
            { trigger: '.healthcheck__heart-and-lungs-icon-dog', content: 'dogs_heart_and_lungs-tooltip' }
          ];

          const tooltipsCats = [
            { trigger: '.healthcheck__teeth-icon-cat', content: 'cats_teeth-tooltip' },
            { trigger: '.healthcheck__eyes-icon-cat', content: 'cats_eyes-tooltip' },
            { trigger: '.healthcheck__ears-icon-cat', content: 'cats_ears-tooltip' },
            { trigger: '.healthcheck__feet-and-claws-icon-cat', content: 'cats_feet_and_claws-tooltip' },
            { trigger: '.healthcheck__skin-and-coat-icon-cat', content: 'cats_skin_and_coat-tooltip' },
            { trigger: '.healthcheck__mobility-icon-cat', content: 'cats_mobility-tooltip' },
            { trigger: '.healthcheck__abdomen-icon-cat', content: 'cats_abdomen-tooltip' },
            { trigger: '.healthcheck__heart-and-lungs-icon-cat', content: 'cats_heart_and_lungs-tooltip' }
          ];

          const tooltipsRabbits = [
            { trigger: '.healthcheck__eating-and-toileting-habits-icon-rabbit', content: 'rabbits_eating_and_toileting_habits-tooltip' },
            { trigger: '.healthcheck__heart-and-lungs-icon-rabbit', content: 'rabbits_heart_and_lungs-tooltip' },
            { trigger: '.healthcheck__coat-icon-rabbit', content: 'rabbits_coat-tooltip' },
            { trigger: '.healthcheck__eyes-and-nose-icon-rabbit', content: 'rabbits_eyes_and_nose-tooltip' },
            { trigger: '.healthcheck__ears-icon-rabbit', content: 'rabbits_ears-tooltip' },
            { trigger: '.healthcheck__teeth-icon-rabbit', content: 'rabbits_teeth-tooltip' },
            { trigger: '.healthcheck__bottom-icon-rabbit', content: 'rabbits_bottom-tooltip' },
            { trigger: '.healthcheck__nails-icon-rabbit', content: 'rabbits_nails-tooltip' }
          ];

          tooltipsCats.forEach(({ trigger, content }) => {
            const element = document.getElementById(content);

            if (element) {
                tippy(trigger, {
                    content: element.innerHTML,
                    allowHTML: true,
                    followCursor: true,
                    plugins: [followCursor],
                    theme: 'health',
                    placement: 'left',
                    arrow: false,
                    maxWidth: 400,
                })
            }
          })
          
          tooltipsDogs.forEach(({ trigger, content }) => {
            const element = document.getElementById(content);
            
            if (element) {
              tippy(trigger, {
                content: element.innerHTML,
                allowHTML: true,
                followCursor: true,
                plugins: [followCursor],
                theme: 'health',
                placement: 'right',
                arrow: false,
                maxWidth: 400,
              });
            }
          });

          tooltipsRabbits.forEach(({ trigger, content }) => {
            const element = document.getElementById(content);
            
            if (element) {
              tippy(trigger, {
                content: element.innerHTML,
                allowHTML: true,
                followCursor: true,
                plugins: [followCursor],
                theme: 'health',
                placement: 'right',
                arrow: false,
                maxWidth: 400,
              });
            }
          });
    }
}