import '@styles/main.scss';
import PetTypeSwiper from './components/pets_type-slider';
import PetsTeamFilters from './components/pets-team-filters';
import NewslettersFilters from './components/newsletter-filter';
import PetHomeSwiper from './components/pets-home-slider';
import PetHealthChecker from './components/health-checks';
import LocationMap from './components/location-map';

class App {
    constructor() {

        jQuery('#toggle').on("click", function() {
            jQuery(this).toggleClass('active');
            jQuery('#overlay').toggleClass('open');
        });

        const header = document.getElementById('site-header');
        const toggle = document.getElementById('toggle');

        if (!header) {
            return;
        }

        if(!toggle) {
            return;
        }

        window.addEventListener('scroll', () => {
            const y = window.scrollY;
            if (y > 100 && !header.classList.contains('is-fixed')) {
                header.classList.add('is-fixed');
                toggle?.classList.add('is-fixed');
            } else if (y < 100) {
                header.classList.remove('is-fixed');
                toggle?.classList.remove('is-fixed');
            }
        });

        new PetTypeSwiper();
        new PetsTeamFilters();
        new NewslettersFilters();
        new PetHomeSwiper();
        new PetHealthChecker();
        this.searchForm();
        this.Menu();
        this.screenWidth();
        new LocationMap();
        // this.SiteWideMessage();
    }

    screenWidth() {
        function setScreenSize() {
          var screenWidth = window.innerWidth;
          console.log(screenWidth, 'screen width');
          // Set a cookie or send an AJAX request with the screen width
          document.cookie = "screen_width=" + screenWidth + "; path=/";
          console.log(document.cookie = "screen_width=" + screenWidth + "; path=/")
        }
    
        // Set the screen size when the page loads
        setScreenSize();
    
        // Update screen size on window resize
        window.addEventListener('resize', setScreenSize);
    };

    searchForm() {
        const trigger = document.querySelector('.lanes-search-link')
        const overlay = document.getElementById('search-overlay')
        const form = document.querySelector('.search-form');

        if(form === null) return;
        if(overlay === null) return;
        if(trigger === null) return;

        trigger.addEventListener('click', e => {
            e.preventDefault();

            overlay.classList.remove('is-hidden');
        })    

        overlay.addEventListener('click', () => overlay.classList.add('is-hidden'));

        form.addEventListener('click', e => e.stopPropagation());
    }

    Menu() {
        const menuItems = document.querySelectorAll('.mega-menu-item-has-children');

        menuItems.forEach(item => {
            item.addEventListener('click', function(event) {
                // event.preventDefault();

                const subMenu = item.querySelector('.mega-sub-menu');
                const subMenuLink = item.querySelector('.mega-menu-link');

                if (subMenu) {
                    const isActive = subMenu.classList.contains('is-active');

                    if (isActive) {
                        subMenu.style.animation = 'slideUp 0.3s ease-out forwards';
                        subMenu.addEventListener('animationend', () => {
                            subMenu.classList.remove('is-active');
                        }, { once: true });
                    } else {
                        subMenu.style.animation = 'slideDown 0.5s ease-out forwards';
                        subMenu.classList.add('is-active');
                    }

                    subMenuLink.classList.toggle('is-active');
                }
            });
        });
    };

    // SiteWideMessage() {
    //     const dismissButton = document.getElementById('site-wide-dismiss') as HTMLElement;
    //     const message = document.getElementById('site-wide-message') as HTMLElement;

    //     dismissButton?.addEventListener('click', () => {
    //         if(message) {
    //             message!.style.display = 'none';
    //         }
    //     })
    // }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
