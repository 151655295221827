import Muuri from 'muuri';
import type Grid from "muuri";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class PetsTeamFilters {
    private muuri: Grid | null = null;
    private roleFilter = '';
    private branchFilter = '';

    constructor() {

        const grid = document.querySelector('.pets-team-wrapper');

        if (!grid) {
            return;
        }

        this.muuri = new Muuri('.pets-team-wrapper');

        const roleSelector = document.querySelector('.team-roles-selector');
        const roleFilters = document.querySelectorAll('.team-roles__item') as NodeListOf<HTMLElement>;

        // Branch Dropdown
        const branchSelector = document.querySelector('.team-branch-selector');
        const branchFilters = document.querySelectorAll('.team-branch__item') as NodeListOf<HTMLElement>;

        if (!roleSelector) {
            console.warn('Missing Filter Selector');
            return;
        }

        if (!branchSelector) {
            console.warn('Missing Filter Selector');
            return;
        }

        const roleDropdown = tippy(roleSelector, {
            content: document.querySelector('.team-roles-dropdown') as any,
            allowHTML: true,
            interactive: true,
            trigger: 'click',
            placement: 'bottom',
            arrow: false,
            theme: 'lanes-dropdown',
            onShow() {
                roleSelector.classList.add('is-active');
            },
            onHide() {
                roleSelector.classList.remove('is-active');
            }
        });

        const branchDropdown = tippy(branchSelector, {
            content: document.querySelector('.team-branch-dropdown') as any,
            allowHTML: true,
            interactive: true,
            trigger: 'click',
            placement: 'bottom',
            arrow: false,
            theme: 'lanes-dropdown',
            onShow() {
                branchSelector.classList.add('is-active');
            },
            onHide() {
                branchSelector.classList.remove('is-active');
            }
        });

        roleFilters.forEach((el) => {
            el.addEventListener('click', () => {
                const value = el.dataset.value;
                if (!value) {
                    return;
                }

                if (this.roleFilter === value) {
                    roleSelector.querySelector('span')!.textContent = 'Please Select';
                    this.roleFilter = '';
                } else {
                    roleSelector.querySelector('span')!.textContent = el.textContent;
                    this.roleFilter = value;
                }

                roleDropdown.hide();

                this.updateFilter();
            })
        });

        branchFilters.forEach((el) => {
            el.addEventListener('click', () => {
                const value = el.dataset.value;
                if (!value) {
                    return;
                }

                if (this.branchFilter === value) {
                    branchSelector.querySelector('span')!.textContent = 'Please Select';
                    this.branchFilter = '';
                } else {
                    branchSelector.querySelector('span')!.textContent = el.textContent;
                    this.branchFilter = value;
                }

                branchDropdown.hide();

                this.updateFilter();
            })
        });

        const clearFiltersButton = document.querySelector('.clear-filters-button');

        if (clearFiltersButton) {
            clearFiltersButton.addEventListener('click', () => {
                // Reset role and branch filters
                this.roleFilter = '';
                this.branchFilter = '';

                const roleSelector = document.querySelector('.team-roles-selector');
                const branchSelector = document.querySelector('.team-branch-selector');

                if (roleSelector) {
                    roleSelector.querySelector('span')!.textContent = 'Select Role';
                }

                if (branchSelector) {
                    branchSelector.querySelector('span')!.textContent = 'Select Branch';
                }

                // Update the filters
                this.updateFilter();
            });
        }

        this.searchFilter();
        
    }    

    // SEARCH FUNCTIONS
    searchFilter() {
        const searchField = document.querySelector('.team-search-field') as HTMLInputElement;
    
        if (!searchField) {
            console.warn('Search field not found.');
            return;
        }
    
        searchField.addEventListener('keyup', () => {
            const searchTerm = searchField.value.toLowerCase();
    
            this.muuri?.filter((item) => {
                const memberNameElement = item.getElement()?.querySelector('.team-member--name');
    
                if (memberNameElement) {
                    const memberName = memberNameElement.textContent?.toLowerCase() || '';
                    return memberName.includes(searchTerm);
                }
    
                return false;
            });
        });
    }

    updateFilter() {
        this.muuri?.filter((item: any) => {
            if (!this.roleFilter.length && !this.branchFilter.length) {
                return true;
            }

            const values = JSON.parse(item.getElement().dataset.categories);

            const rolePass = !this.roleFilter.length || values.includes(this.roleFilter);
            
            const branchPass = !this.branchFilter.length || values.includes(this.branchFilter);

            return rolePass && branchPass;
        });
    }

}            